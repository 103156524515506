var Handlebars = require("../../../../../../srv/zulip-npm-cache/a0b65cf9f3db19b23eaecd4bb80cb8a592bab2e5/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div data-full-path=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"full_path") || (depth0 != null ? lookupProperty(depth0,"full_path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"full_path","hash":{},"data":data,"loc":{"start":{"line":10,"column":29},"end":{"line":10,"column":44}}}) : helper)))
    + "\" data-line-no=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"line_number") || (depth0 != null ? lookupProperty(depth0,"line_number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":10,"column":60},"end":{"line":10,"column":77}}}) : helper)))
    + "\">\n            <div class=\"stackframe\">\n                <i class=\"fa fa-caret-right expand\"></i>\n                <span class=\"subtle\">at</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"function_name") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"subtle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"show_path") || (depth0 != null ? lookupProperty(depth0,"show_path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_path","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":52}}}) : helper)))
    + ":"
    + alias4(((helper = (helper = lookupProperty(helpers,"line_number") || (depth0 != null ? lookupProperty(depth0,"line_number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":70}}}) : helper)))
    + "</span>\n            </div>\n            <div class=\"code-context\" style=\"display: none\">\n                <div class=\"code-context-content\">"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"context") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":23,"column":31}}})) != null ? stack1 : "")
    + "</div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"function_name") : depth0)) != null ? lookupProperty(stack1,"scope") : stack1), depth0))
    + "<b>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"function_name") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</b>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"focus") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":29},"end":{"line":22,"column":67}}})) != null ? stack1 : "")
    + "><span class=\"line-number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"line_number") || (depth0 != null ? lookupProperty(depth0,"line_number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":22,"column":94},"end":{"line":22,"column":111}}}) : helper)))
    + "</span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"line") || (depth0 != null ? lookupProperty(depth0,"line") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line","hash":{},"data":data,"loc":{"start":{"line":22,"column":119},"end":{"line":22,"column":129}}}) : helper)))
    + "</div>";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"focus-line\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stacktrace-header\">\n    <div class=\"warning-symbol\">\n        <i class=\"fa fa-exclamation-triangle\"></i>\n    </div>\n    <div class=\"message\"><strong>Error:</strong> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"error") || (depth0 != null ? lookupProperty(depth0,"error") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"error","hash":{},"data":data,"loc":{"start":{"line":5,"column":49},"end":{"line":5,"column":60}}}) : helper)))
    + "</div>\n    <div class=\"exit\"></div>\n</div>\n<div class=\"stacktrace-content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"stackframes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});